@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Roboto Condensed", sans-serif; /* Name your font */
  src: url("../../../public/assets/fonts/RobotoCondensed-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: 'Clash_Grotesk';
  src: url('../../../public/assets/fonts/ClashGrotesk-Variable.ttf') format('truetype');
  
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('../../../public/assets/fonts/Manrope-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('../../../public/assets/fonts/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


body{
  overflow-x: hidden !important;
  -webkit-user-select: none !important;  /* Safari */
  -moz-user-select: none !important; /* Firefox */
  -ms-user-select: none !important;     /* Internet Explorer/Edge */
  user-select: none !important; 
  cursor:crosshair;
}
.d_tabs_container {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  margin-top: 17px;
  justify-content: space-around;
  -webkit-animation: tabs_anim 2s ease-in-out;
  animation: tabs_anim 2s ease-in-out;
}

.desktop-nav-label {
  font-family: "Roboto Condensed", sans-serif !important;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold !important;
  transition: 0.4s ease-in-out;
}

.show-compoment-width {
  width: 100%;
  -webkit-animation: show-width 1s ease-in-out;
  animation: show-width 1s ease-in-out;
}
.desktop-nav-label:hover {
  color: chocolate;
  transition: 0.4s ease-in-out;
}

.d_tabs_container h1:hover {
  transition: 0.5s ease-in-out;
  opacity: 1;
  transform: scale(1.01);
}

.topnav-indicator {
  font-family: Roboto Condensed;
  color: rgb(20, 240, 185);
}

.label-land {
  -webkit-animation: label-enter 0.5s ease-in-out;
  animation: label-enter 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
::-webkit-scrollbar {
  height: 12px;
  width: 5px;
  background: #161c24;
}

::-webkit-scrollbar-thumb {
  background: #bbc7f0;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: #bbc7f0;
}

@keyframes label-enter {
  0% {
    opacity: 0;
  }
}
.d_resumespan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.8;
  height: 30px;
  padding: 5px;
  font-family: "Roboto Condensed", sans-serif !important;
  font-weight: bold;
  transition: 0.4s ease-in-out;
}
.d_resumespan:hover {
  border: 2px solid chocolate;

  transition: 0.4s ease-in-out;
}

.header {
  -webkit-animation: header-animation 0.5s ease-in-out;
  animation: header-animation 0.5s ease-in-out;
}






.scale-image-parent {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.scale-image{
  overflow: hidden;
  transition: all 0.5s;
}

.scale-image-parent:hover .scale-image ,
.scale-image-parent:focus .scale-image  {
  transform: scale(1.1);
}

.scale-image-in {
  overflow: hidden;
  transition: all 0.5s;
}


.scale-image-parent:hover .scale-image-in ,
.scale-image-parent:focus .scale-image-in  {
  transform: scale(0.9);
}
















/*my avater**************************************/
.avatar-box {
  margin-left: -15px;
}

.showprofile {
  visibility: visible;
  -webkit-animation: avatar 2s ease-in-out;
  animation: avatar 2s ease-in-out;
}

.my-avater {
  box-shadow: 1px 2px 6px 1px rgb(0, 0, 0);
}

/************************* Box links ************************/
.link-icons {
  position: fixed !important;
  z-index: 100;
}

.d_fix_contact_line {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  align-items: center;
  transition: 2s ease-in-out;
}


.d_fix_contact_line a {
  box-shadow: 1px 2px 5px 1px rgb(107, 103, 103);
}

.hide {
  opacity: 0;
}
.targets-container-width {
  width: 0%;
}
.show-compoment {
  opacity: 1 !important;
}
.show-compomenht-width {
  width: 34%;
  -webkit-animation: glowWidth 1.5s ease-in-out;
  animation: glowWidth 1.5s ease-in-out;
}
.fix_contact_l {
  visibility: hidden;
}

.hide-left-link {
  -webkit-animation: animateHideNav 1s ease-in-out;
  animation: animateHideNav 1s ease-in-out;
  visibility: hidden;
  transform: translateY(500%);
  opacity: 0;
  scale: 0.5;
}
.show-left-link {
  visibility: visible;
  transform: translateY(0%);
  -webkit-animation: animateShowNav 1s ease-in-out;
  animation: animateShowNav 1s ease-in-out;
}

.canvas-motion {
  opacity: 1;
  -webkit-animation: showMotion 3s ease-in-out;
  animation: showMotion 3s ease-in-out;
}

.hide-star-canvas {
  opacity: 0;
  -webkit-animation: hide-animation 1s ease-in-out;
  animation: hide-animation 1s ease-in-out;
  transform: scale(0);
}
.show-star-canvas {
  opacity: 1;
  -webkit-animation: showMotion 1s ease-in-out !important;
  animation: showMotion 0.41s ease-in-out !important;
  transform: scale(1);
}

@keyframes animateHideNav {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes showMotion {
  0% {
    opacity: 0;
    transform: scale(0.3) !important;
  }
  90% {
    opacity: 0;
  }
}
@keyframes show-star-animation {
  0% {
    opacity: 0.5;
    transform: scale(0.4);
  }
}
@keyframes hide-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
}
.hidetabs {
  visibility: hidden !important;
}
@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 780px) {
}

@media only screen and (max-width: 350px) {
}


.left-nav-links {
  -webkit-animation: nav-links-show 4.8s ease-in-out;
  animation: nav-links-show 4.8s ease-in-out;
}
.initial_nav_link{
  opacity: 1;
  transition:0.7s ease-in-out;
  -webkit-animation: animateNav 0.7s ease-in-out;
  animation: animateNav 0.7s ease-in-out;

  
}
.nav-box {
  transition: 4s ease-in-out;
  transform: rotate(0deg);
}
.spintab {
  -webkit-animation: spinanim 4s ease-in-out;
  animation: spinanim 4s ease-in-out;
  background-color: rgb(147, 238, 155) !important;
  transition: 4s ease-in-out;
  transform: rotate(45deg);
}

@keyframes spinanim {
  0% {
    transform: rotate(180deg);
  }
}
@keyframes animateNav {
0%{
  opacity: 0;
  scale: 0.8;
  transform: translateY(5%);
}  
}
@keyframes header-animation {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }
}
@keyframes nav-links-show {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
    transform: translateY(300%);
  }
}

@keyframes show-width {
  0% {
    width: 0%;
  }
}
@keyframes animateComponent {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
}
@keyframes glowWidth {
  0% {
    width: 0%;
  }
  50% {
    width: 0%;
  }
}
