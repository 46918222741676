.footermain{
    display: flex;
    width: 100%;
    height: 100%;
}
.footer_lines{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
    gap: 20px;
}



.footer_circle{
    width: 25px;
    height: 35px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
   
}

.line-box{
    width: 3px;
    height: 3px;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    -webkit-animation: line_box-animate 1s infinite linear;
    animation: ine_box-animate 1s infinite linear;
  }
  .footer_text Typography{
    font-family: "Roboto Condensed", sans-serif !important;
  }
  Typography{
    font-family: "Roboto Condensed", sans-serif !important;
  }





  .d_footer_click {
    padding: 8px;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-family: "Roboto Condensed", sans-serif;
    text-decoration: none;
    
    border: 1px solid transparent;
  }

  .d_footer_click:hover{
    transition: 0.5s ease-in-out;
   border: 1px solid #7c7d7d;
   cursor: pointer;
    
   
  }


  .footer-author{
    display: flex;
    width: 100%;
    align-items: center;
  padding: 20px 0;
    justify-content: center;
  }

  
@keyframes ine_box-animate{
    0% {
      transform: translateY(-300%);
      opacity: 1;
    }
  
    100% {
      transform: translateY(900%);
      opacity: 0;
    }
  }