


.cardx-container{
    padding-left:0px;
    margin-bottom: 60px;
    font-family: "Roboto Condensed", sans-serif!important;


}
.cardx-point{
    width: 15px;
    height: 15px;
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    
}

.card-details{
    font-family: "Roboto Condensed", sans-serif!important;
 

}




  .experience_h {

    font-family:"Roboto Condensed", sans-serif;
    font-weight: bold;
    margin-bottom: 30px;
    width: 100%;
    align-items: center;
  }
  

  .skill-box{
    padding: 5px 0 ;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 15px;
  }
.skill-box-text{
    font-family: "Roboto Condensed", sans-serif!important;
}



.expe-right-box{
  width: 20px;
  height: 15px;
  border-top-width: 1px;
  border-top-style: solid;

  border-right-width: 1px;
  border-right-style: solid;

  margin-right: 10px!important;
  margin-top: 5px!important;
}
.expe-left-box{
  width: 20px;
  height: 15px;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  border-left-width: 1px;
  border-left-style: solid;

  margin-left: 10px!important;
  margin-top: -20px!important;

}

