.m-profile-picture{


    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 30px 0px;


}
.profile-image-container{
    width: 200px;
    height: 170px;
    border-radius: 5px;
    z-index: 6;

    overflow: hidden;
}
.profile-image{
    width: 200px;
    height: 170px;
    border-radius: 5px;
    z-index: 6;
    filter: grayscale(40%); 
  

}


.m-profile-container{
    display: flex;
    flex-direction: column;

}
.profile-frame{
    width: 200px;
    height: 170px;
    border-radius: 5px;
    border-width: 1px;

    border-style: solid;
    border-color: #05af85;
    position: absolute;
transition: 0.5s ease-in-out;
margin-top: 10px;
margin-left: 10px;
  
    z-index: 5;
}

.m-footer_lines{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}



.m-footer_circle{
    width: 20px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
   
}

.m-line-box{
    width: 3px;
    height: 3px;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    -webkit-animation: line_box-animate 1s infinite linear;
    animation: ine_box-animate 1s infinite linear;
  }


 