

.project-h Typography {
  font-family: "Roboto Condensed", sans-serif;
}
.work {
  display: flex;
  width: 100%;
}

.work-label {
  width: 50%;
  height: 100%;
  background: rgba(3, 161, 3, 0);
  display: flex;
  flex-direction: column;
  margin-right: -50px;
}

.d_work_p_n {
  color: rgb(5, 175, 133);
  font-family: "Roboto Condensed", sans-serif;
  font-size: 19px;
  font-weight: bold;
}
.d_work_p_c {
  border-radius: 15px;
  font-family: "Roboto Condensed", sans-serif;
  z-index: 200;
}
.d_work_p_c  Typography{
  
    font-family: "Roboto Condensed", sans-serif !important;
  
  }
.clickmebtn {
  padding: 5;
  border-radius: 5px;
  cursor: pointer;
  font-family: Roboto condensed;
  font-weight: bold;
  color: rgb(6, 22, 59);
  transition: 2s ease-in-out;
}

.d_work_p_toolsr {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: left;
}

.d_work_p_link {
  padding: 10px 0px;
  margin-top: -20px;
}

.work_imagecontainer {
  display: flex;
  transition: 0.3s ease-in-out;
}





.show-project-content{
  -webkit-animation: animate-project 1s ease-in-out;
  animation: animate-project 1s ease-in-out;


}
@media only screen and (max-width: 780px) {
  .project-content {
z-index: 2;
transition: 0.4s ease-in-out;
opacity: 0;
    
  }

  .project-content:hover {
    opacity: 1;
    transition: 0.4s ease-in-out;
  }
  
  
}



@keyframes animate-project {
  0%{
    transform: scale(0.95);
  }
}