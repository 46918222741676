
.profile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-size: cover;
}
.profile-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 80%;
}

.profile-content {
  width: 65%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.profile-first-name {
  font-family: "Roboto Condensed", sans-serif ;
  font-weight: bold;
  -webkit-animation: contentapperI 1s ease-in-out;
  font-size: 50px !important;
  animation: contentapperI 1s ease-in-out;
}

.d_content_name {
  font-family: "Roboto Condensed", sans-serif ;

  -webkit-animation: contentapperI 2s ease-in-out;
  animation: contentapperI 2s ease-in-out;
}
.mobileu {
  display: none;
}

.profile-picture {
  display: flex;
  position: relative;
  width: 380px;
  height: 260px;  
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-animation: draw-picture 1s ease-in-out;
  animation: draw-picture 1s ease-in-out;
}
.profile-picture_frame {
  transition: 0.5s ease-in-out;
  width: 345px;
  height: 99%;
  background: rgba(3, 161, 3, 0);
  border-radius: 15px;
  border-width: 2.3px;
  border-style:  solid;
  margin-left: 25px;
  margin-top: 25px;
  transition: 0.5s ease-in-out;
  -webkit-animation: drawframe 4.8s ease-in-out;
  animation: drawframe 4.8s ease-in-out;
}
.hide-frame{
  margin-left: 0px;
  margin-top: 0px;
}

.p_front {
  border-radius: 15px;
  width: 350px;
  position: absolute;
  -webkit-animation: drawpic 1s ease-in-out;
  animation: drawpic 1s ease-in-out;
  overflow: hidden;
  filter: grayscale(20%); 
}

.p_front_image{
  transition: all 0.5s;
  scale: 1.15;
}
.profile-photo-cover{
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  position: absolute;
  background-color: rgba(4, 55, 150, 0.3);
  transition: 0.5s ease-in-out;
  }
.profile-photo-cover:hover{
background-color: rgba(70, 56, 175, 0.2);
transition: 0.5s ease-in-out;
cursor: pointer;
}

.intro_lines {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: space-between;
}

.d_introline_l {
  width: 30px;
  height: 30px;
  border-bottom-left-radius: 4px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-style: solid;
  transition: 1s ease-in-out;
  -webkit-animation: showline=l 4s ease-in-out;
 animation: showline-l 4.2s ease-in-out;
}

.d_introline_r {
  width: 30px;
  height: 30px;
  border-bottom-right-radius: 4px;
  border-bottom-width: 2px;
  border-right-width: 2px;
  transition: 1s ease-in-out;
  -webkit-animation: showline=r 4s ease-in-out;
animation: showline-r 4.2s ease-in-out;
}

.d_content_brief {

  -webkit-animation:   2s ease-in-out;
  animation: introanim 2s ease-in-out;
}

.brief_swip {
  font-family: "Roboto Condensed", sans-serif !important;
  font-size: 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;


 
  -webkit-animation: introanim 2.5s ease-in-out;
  animation: introanim 2.5s ease-in-out;
}

.d_hand {
  display: flex;
  transition: 1s ease-in-out;
  margin: 0px 30px;
  transform: rotate(90deg);
  -webkit-animation: hand_anim 2s infinite;
  animation: hand_anim 2s infinite;
}



  /*Vertical Sliding*/
.slidingVertical {
height: 40px;
margin-left: 20px
}







#container {

  top: 0;
  bottom: 0;
  left: 0;
  filter: url(#threshold) blur(0.6px);
}


#text1,
#text2 {
  position: absolute;
  width: 100%;
  display: inline-block;
  font-family: "Roboto Condensed", sans-serif,;
  text-align: left;
  user-select: none;
  font-weight: bold;
  color:#069e78!important;
}


/*topToBottom Animation*/
@keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes hand_anim {
  0% {
    transform: translateX(-5%) rotate(90deg);
  }

  100% {
    transform: translateX(20%) rotate(90deg);
  }
}

@media only screen and (max-width: 930px) {
  .intro_lines {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
  }
  
  .d_introline_l {
    width: 20px;
    height: 20px;

  }
  
  .d_introline_r {
    width: 20px;
    height: 20px;

  }
  .desktop {
    display: none;
  }
  .mobileu {
    display: flex;
  }
  .profile-container {
  
    justify-content:space-between;

  }
  .profile-content {
    padding-top: 40px;
    width: 100%!important;
   gap:10px !important ;

  }
  .profile-first-name{
    font-size: 30px !important;
  }
 
  
  .brief_swip { 
    margin-top: 70px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%!important;
  }
 
    
  .slidingVertical{
  width: 100%!important;
  margin-left: 0;
  }


  .swipe-stack-mobile{
    font-size: 35px!important;
    
  }
}


@keyframes contentapperI {
  0% {
    opacity: 0;
   
  }
50%{
  opacity: 0;
  transform: translateY(-20%);
}
}
@keyframes introanim {
  0%{
    opacity: 0;
  }
  60%{
    opacity: 0;
    transform: translateY(-10%);
  }
  
  
}


@keyframes showline-r {
  
  0%{
    opacity: 0;
  }
  70%{
    opacity: 0;
    transform: translateY(20%) translateX(-5%);
  }
}
@keyframes showline-l {
  
  0%{
    opacity: 0;
  }
  70%{
    opacity: 0;
    transform: translateY(20%) translateX(-5%);
  }
}

@keyframes draw-picture {
  0%{
 opacity: 0;
transform: scale(0.5);
    }
    50%{
      opacity: 0;
      transform: scale(0.5);
    
    }
}
@keyframes drawframe {
    
  0%{
  margin-top: 0px;
  margin-left: 0px;
  width: 250px;
  height: 250px;
  }
  50%{
  margin-top: 0px;
  margin-left: 0px;
  width: 250px;
  height: 250px;
  
  }
}